
.App{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

button:hover{
  scale: 1.1;
  transition: all 0.4s ease-in-out;
}

.blur {
  filter: blur(5px);
}


/* NavBar CSS STARTS */

.navbar-container{
  position: fixed;
  z-index: 1;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
}

.message{
  background-color: rgb(128, 0, 128, 0.8);
  color: white;
  padding: 10px;
  text-align: center;
  height: 5vh;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message small{
  font-size: small;
}

.navbarbrandnameheader-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95vw;
  margin: auto;
  height: 10vh;
  margin-top: 5px ;

}

.navbarbrandnameheader-container .brand-logo{
  display: flex;
  align-items: flex-end;
}

.navbarbrandnameheader-container .other_details{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 55%;
}

.other_details .catalogues{
  display: flex;
  flex-direction: column;
}

.other_details .catalogues select{
  padding: 10px;
  border-radius: 5px;
}

.other_details .cart_container{
  background-color: #00B900;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  margin: 0px 5px;
  position: relative;
}

.other_details .cart_container p{
  position: absolute;
  bottom: 20px;
  right: 0;
  background-color: orange;
  padding: 3px 5px;
  border-radius: 20%;
  color: white;
}

.contactDetails-container{
  display: flex;
  align-items: flex-end;
  background-color: rgb(0, 185, 0, 0.8);
  border-radius: 10px 10px 0px 0px;
  padding: 3px 10px;
  position: fixed;
  bottom: 0;
  right: 10px;
}

.navbarbrandnameheader-container img{
  margin: 10px;
}

.navbarbrandnameheader-container .brand-logo-card{
  display: flex;
  align-items: center;
}


.navbarbrandnameheader-container .brand-logo-card .brand-logo{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.navbarbrandnameheader-container .contacts-icon{
  width: 30px;
}

 .contactDetails-container .contacts-card p{
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 5px;
  color: white;
}

.contactDetails-container .contacts-card small{
  color: white;
}

.NavBarMenu-container .mobile-navbar-container .navbar-hamburgericon-card{
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin: 5px auto;
  border-radius: 10px;
}


.navbar-hamburgericon-card .search_input_container{
  background-color: rgb(211, 211, 211);
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 80%;
  padding: 10px;
}

.search_input_container button{
  background-color: transparent;
  border: none;
}

.navbar-hamburgericon-card .search_input_container .searchInput{
  border: none;
  padding: 10px;
  border-radius: 10px;
  width: 90%;
}
.search_input_container .searchInput::placeholder {
  padding: 0px 10px;
}

.NavBarMenu-container .mobile-navbar-container .navbar-hamburgericon-card img{
  width: 30px;
  height: 25px;
  margin: auto;
}


.NavBarMenu-container .mobile-navbar-container .navbar-hamburgericon-card h3{
  color: white;
  margin-left: 0;
}

.navbar-container .search-container{
  width: 95vw;
  margin: auto;
  display: flex;
  justify-content: center;
}

.navbar-container .search-container input{
  width: 60%;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid grey;
}

.search-container button{
  margin-left: 5px;
  border-radius: 5px;
  padding: 10px 15px;
}

.MobileDrawerMenu-container{
  background-color: white;
  width: 100vw;
  position: absolute;
  left: 0;
  bottom: -90vh;
  height: 90vh;
  border-radius: 0px 10px;
  border: 1px solid rgb(128, 128, 128, 0.4);
  transform: translateY(110%);
  transition: transform 0.5s ease-in-out;
  overflow-y: scroll;
  padding-bottom: 10vh;
  box-sizing: border-box;
}

.MobileDrawerMenu-container.open{
  transform: translateY(0);
}

.MobileDrawerMenu-container .drawer_heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 5px auto;
  background-color: lightgrey;
  border-radius: 5px;
}

.MobileDrawerMenu-container.open p{
  background-color: lightgrey;
  padding: 8px 10px;
  box-sizing: border-box;
  width: 90%;
  margin-left: 20px;
  font-weight: 600;
  font-size: large;
}

.MobileDrawerMenu-container .drawer_heading p{
  margin-left: 20px;
  font-weight: 600;
  font-size: large;
  color: black;
  background-color: transparent;
}

.close{
  background-color: none;
  color: black;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 5px 10px;
  font-weight: 500;
  width: 40px;
  height: 35px;
  font-size: large;
  margin-right: 10px;
}

.close:hover{
  background-color: red;
  color: white;
  border: none;
}

.MobileDrawerMenu-container .productSearch-card{
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileDrawerMenu-container .productSearch-card input{
  width: 80%;
  height: 5vh;
  border-radius: 10px;
  border: 1px solid rgb(255, 165, 0, 0.6);
  padding: 5px 10px;   
}

.MobileDrawerMenu-container .productSearch-card img{
  height: 40px;
  margin-left: 10px;
}

.MobileDrawerMenu-container ul{
  list-style: none;
}

.MobileDrawerMenu-container ul p{
  background-color: black;
  padding: 5px;
}

.MobileDrawerMenu-container ul li {
  margin-top: 10px;
}


.slideshow-container {
  width: 95vw;
  height: 45vh;
  border-radius: 10px;
  margin: 20vh auto 30px auto;
  position: relative;
}

.slidesContainerStyles{
  display: flex;
  height: 100%;
  transition: transform ease-out 0.3s;
}

.slidesContainerOverflowStyles{
  overflow: hidden;
  height: 100%;
}

.slideshow-container .navigationdots-container{
  display: flex;
  justify-content: center;
}

.dotStyle{
  margin: 5px;
  cursor: pointer;
  font-size: 20px;
  width: fit-content;
  color: grey;
}

.rightArrowStyles{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 22px;
  font-size: 45px;
  color: rgb(128, 128, 128, 0.5);
  z-index: 1;
  cursor: pointer;
}
.leftArrowStyles{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 22px;
  font-size: 45px;
  color: rgb(128, 128, 128, 0.5);
  z-index: 1;
  cursor: pointer;
}


/* Catalogue Section */
.cataloguesection-container{
  width: 95vw;
  margin: 50px auto;
}

.cataloguesection-container h3{
  /* text-align: center; */
  text-transform: uppercase;
  border-left: 5px solid #00B900;
  font-size: x-large;
  padding-left: 10px;
}

.cataloguesection-container p{
  color: grey;
  margin-left: 10px;
  font-weight: 500;
  font-size: large;
}

.cataloguesection-container h3 span{
  font-size: small;
  font-weight: 400;
}

.cataloguesection-container .catalogues-card{
  display: flex;
  /* justify-content: space-between; */
}

.catalogues-card .catalogue{
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgb(128, 128, 128, 0.3);
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 220px;
}

.catalogues-card .catalogue .catalogueInfo h5,
.catalogues-card .catalogue .catalogueInfo p{
  margin: 0;
  margin-bottom: 5px;
  color: grey;
}

.catalogueInfo{
  width: fit-content;
}

.catalogueInfo .catalogueInfo-img{
  border-radius: 10px;
  scale: 0.7;
}

.catalogues-card .catalogue img{
  width: 50%;
}

.catalogue .btns{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.catalogues-card .catalogue button{
  border-radius: 10px;
  border: none;
  padding: 8px;
  background-color: #00B900;
  color: white;
  margin-top: 5px;
}

.catalogue .btns .download{
  background-color: royalblue;
}


/* Product Listing section Starts */

.productlisting-container{
  width: 95vw;
  margin: auto;
  /* margin: 20vh auto 0px auto; */
}

.productlisting-container h3{
  text-transform: uppercase;
  border-left: 5px solid #00B900;
  font-size: x-large;
  padding-left: 10px;
}

.productlisting-container h3 span{
  font-size: small;
  font-weight: 400;
}

.productslist{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.productlisting-container .products-card{
  width: 45%;
  height: 350px;
  border-radius: 5px;
  padding: 10px 5px ;
  text-align: center;
  margin: 5px 0px;
  border: 0.5px solid lightgrey;
}

.productlisting-container .products-card img{
  width: 100%;
  height: 65%;
}

.productInfo{
  margin-bottom: 5px;
}

.productInfo p{
  margin: 3px;
  text-align: left;
}

.productlisting-container .products-card .productInfo .price{
  font-size: small;
}

.productlisting-container .products-card .productInfo p span{
  margin: 2px;
  font-weight: 500;
  font-size: larger;
}

.products-card button,
.productlisting-container button{
  width: 60%;
  padding: 10px 5px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  color: black;
  font-weight: 500;
  background-color: white;
}


.productlisting-container button:hover{
  border: 1px solid transparent;
  color: white;
  background-color: #00B900;
}

.products-card button:hover{
  border: 1px solid transparent;
  color: white;
  background-color: #00B900;
}

.productlisting-container .btns{
  text-align: center;
  margin-top: 10px;
}

.more-btn{
  background-color: #00B900;
  width: 150px;
  color: white;
  border: transparent;
  border-radius: 5px;
  font-weight: 500;
}

/* ProductOnSale section starts */

.productOnSale_displayboard{
  display: flex;
}

 .catalogue_container{
  display: none;
}



.displayboard-container{
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(245, 245, 220, 0.8);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
}

.no_displayboard_container{
  display: none;
}

.alterdisplayboard-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 220, 0.8);
  padding: 20px;
  margin: auto;
}

.alterdisplayboard-container img{
  width: 250px;
  margin: auto;
}

.displayboard-container .displayboard p{
  color: grey;
}

.displayboard h1{
  text-align: center;
}

.displayboard .details{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.displayboard .price{
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
}

.displayboard h3, 
.displayboard h4{
  margin: 0;
  margin-bottom: 10px;
}

.displayboard h4{
  color: crimson;
}

.displayboard h3{
  background-color: #FFA500;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  width: fit-content;
  margin-left: 10px;
}
.displayboard button{
  width: 100px;
  height: 40px;
  margin-top: 10px;
  background-color: #fe4703;
  border: none;
  color: white;
  font-size: medium;
  margin: auto;
}

.displayboard h4{
  text-decoration: line-through;
}

.displayboard small{
  background-color: #00B900;
  padding: 3px 0px;
  color: white;
  border-radius: 2px;
  display: block;
  width: 100px;
  text-align: center;
}

.displayboard-container .displayboardImage{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.displayboard-container .displayboardImage img{
  width: 100px;
  height: 150px;
  margin: 10px 20px;
}

.displayboardImage h3{
  color: lightgrey;
  width: fit-content;
  font-size: 4rem;
}

.productonsale-container{
  width: 95vw;
  height: 45vh;
  border-radius: 10px;
  margin: 20vh auto 30px auto;
  position: relative;
}

/* Footers Section Starts  */

.footer-container{
  margin-top: 10px;
  padding: 30px 30px 30px 20px;
}

.footer-container p{
  color: white;
  font-weight: 600;
  text-align: center;
}

.footer-container .footer_details{
  display: flex;
  justify-content: space-between;
  width: 90vw;
  margin: auto;
  flex-wrap: wrap;
}

.footer-container h3{
  text-transform: uppercase;
  color: white;
}

.footer-container .footer-brandInfo{
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer-container .footer-brandInfo p{
  color: white;
  font-weight: 600;
}

 .footer-brandServices .shipping{
  margin-bottom: 0;
}

.footer-brandServices small,
.footer-brandServices li{
  color: white;
}

.footer-container .footer-brandInfo img{
  width: 150px;
  border-radius: 50%;
}

.footer-container .contactInfo ul{
  padding: 0;
  margin: 0;
  color: white;
}


.footer-container .brandmedia-card img{
  width: 50px;
  margin-left: 10px;
}

.footer-container ul{
  list-style: none;
  margin: 0;
}

.footer-container ul li {
  margin: 5px;
  cursor: pointer;
}

.map-container{
  width: 150px;
  border-radius: 10px;
}

/* Shopping Cart Container Section Starts  */
.shoppingcart-container{
 width: 100vw;
 margin: auto;
 position: absolute;
 padding: 5px 10px;
 box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
 box-sizing: border-box;
 background-color: white;
 left: 0;
  top: 0;
  height: 100vh;
  border-radius: 0px 10px;
  border: 1px solid rgb(128, 128, 128, 0.4);
  transform: translateY(100vh);
  transition: transform 0.5s ease-in-out;
}
 

.shoppingcart-container .back_button{
  display: none;
}

.shoppingcart-container.open{
  transform: translateY(0);
}

.shoppingcart-container .cart_header{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
  width: 90vw;
  margin: auto;
}

.shoppingcart-container.open .cartDetails_card{
  height: 90%;
  overflow-y: scroll;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.cartTotal_extra_card{
  display: none;
}

.cart_header p{
  font-weight: 500;
  font-size: larger;
}

.cart_header button{
  height: fit-content;
  padding: 5px 10px;
  border-radius: 30% ;
  border: none;
  color: white;
  background-color: crimson;
  font-size: larger;
  width: 40px;
}

.cart_header button:hover{
  transform: scale(1.1);
}

.shoppingcart-container .cart-msg{
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin: 10px 0px 5px 0px;
  padding: 5px;
}

.shoppingcart-container.open .cart{
  margin-bottom: 10px;
  box-sizing: border-box;
  height: fit-content;
}

.shoppingcart-container .cart-msg img{
  height: 40px;
  margin-right: 30px;
}
.shoppingcart-container .cart-msg p{
  max-width: 100%;
  color: grey;
  margin: 0;
}

.shoppingcart-container .products{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  position: relative;
  width: 90%;
  margin: 15px auto;
  border: 0.5px solid lightgrey;
}

.products .counter{
  display: flex;
  margin-left: 10px;
}

.products .counter span{
  width: 20px;
  margin: 0px 2px ;
  text-align: center;
}

.products .counter .add{
  border: none;
  font-size: large;
  background-color: #00B900;
  color: white;
}

.products .counter .subtract{
  border: none;
  font-size: large;
  background-color: grey;
  border-radius: 5px;
  color: white;
}

.shoppingcart-container .products img{
  width: 60px;
}

.products .product-image{
  margin-right: 30px;
}

.products .product-info{
  width: fit-content;
  margin: auto;
}

.shoppingcart-container .products p,
.shoppingcart-container .products h3{
  width: 100%;
  margin: 0px;
}

.shoppingcart-container .products h3{
  font-weight: 400;
  font-size: smaller;
}

.shoppingcart-container .carttotal-container{
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100vw;
  height: 10%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
}

.shoppingcart-container .carttotal-container::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.2);
  z-index: 1;
}

.carttotal-container h2{
  margin: 0;
}

.carttotal-container button{
  background-color: orange;
  border-radius: 5px;
  border: transparent;
  padding: 0px 35px;
  text-align: center;
  font-size: x-large;
}

/* Single Product Page starts  */

.singleproductpage-container{
  margin: auto;
  width: 90vw;
  margin-top: 20vh;
}

.singleproductpage-container .product-card{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.singleproductpage-container .product-card img{
  height: 200px;
  padding: 5px;
}

.singleproductpage-container .product-details{
  width: 90vw;
  padding: 10px;
  border-bottom: 1px solid grey;
}

.singleproductpage-container .product-details h3{
  margin: 0;
  font-size: x-large;
  font-weight: 300;
}

.singleproductpage-container .product-details p{
  font-size: xx-large;
  margin: 5px;
  font-weight: 500;
}

.cart-buttons{
  width: 90vw;
  margin: auto;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 10px 0px;
  border-bottom: 1px solid grey ;
}

.cart-buttons .counter{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.cart-buttons .counter button{
  font-size: xx-large;
  background-color: rgb(128, 128, 128, 0.1);
  border: transparent;
  padding: 5px 15px;
}

.cart-buttons .counter span{
  margin: 0 10px;
  min-width: 30px;
  font-weight: 400;
  font-size: x-large;
  text-align: center;
}

.productdescription-container{
  margin: 15px 0px;
}

.productdescription-container small {
  padding: 0px 10px;
  border-left: 5px solid orange;
}

.singleproductpage-container .productdescription-container p{
  color: grey;
  margin: 2px;
}


/* WhatsAPP chant interface section starts  */

.whatsappChatInterFace-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
  background-color: rgb(245, 245, 220);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whatsappChatInterFace-container.open {
  transform: translateY(0);
}

.whatsappChatInterFace-container .chatHeadingMessage{
  width: 80%;
  margin: 0px auto;
  text-align: center;
}

.chatHeadingMessage p{
  margin: 8px;
  font-weight: 500;
}

.whatsappChatInterFace-container .chatNavBar{
  background-color: rgb(0, 185, 0, 0.8);
  display: flex;
  width: 90%;
  border-radius: 10px;
  margin: 40px auto 0px auto;
  padding: 5px 0px;
}

.chatNavBar img{
  width: 50px;
  margin-left: 20px;
}

.chatNavBar .onlineStatus{
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chatNavBar .end{
  background-color: white;
  border: none;
  height: 30px;
  position: absolute;
  right: 10%;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 10px;
}

.chatNavBar .onlineStatus p{
  margin: 0;
  color: white;
  font-weight: 600;
  font-size: large;
}

.whatsappChatInterFace-container .messages-container{
  background-color: white;
  height: 80vh;
  width: 95vw;
  margin: auto;
  border-radius: 20px;
  margin: 10px auto;
  background-image: url(../src/img/background.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

.messages-container p{
  width: 80%;
  text-align: center;
  margin: auto;
  background-color: rgb(128, 128, 128, 0.6);
  padding: 10px;
  border-radius: 10px;
  color: white;
  margin-top: 30px;
}

.messages-container .message_examples{
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  margin-top: 10px;
  margin-right: 40px;
}

.message_examples .welcome_message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.welcome_message img{
  width: 50px;
  height: 50px;
  margin-left: 10px;
}



.messages-container .message_examples p{
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
}

.welcome_message p{
  background-color: white;
  color: black;
  text-align: left;
}


.whatsappChatInterFace-container .messageInput_container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 95vw;
  margin: 0px auto 50px auto;
}

.whatsappChatInterFace-container .messageInput_container input{
  width: 75%;
  height: 50px;
  border: 1px solid #00B900;
  border-radius: 10px;
  padding: 5px 10px;
}

.whatsappChatInterFace-container .messageInput_container button{
  background-color: #00B900;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 0px 15px;
  height: 30px;
}


/* Our services section */

.ourservices_container{
  background-color: antiquewhite;
  width: 90vw;
  margin: auto;
  padding: 5px 0px;
  margin-top: 5px;
  border-radius: 10px;
}



.ourservices_container p{
  text-align: center;
  font-weight: 500;
  font-size: xx-large;
  margin-top: 40px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.ourservices_container .list_of_services{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.list_of_services .services-card{
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  animation: blink 1s infinite;
}

.services-card small{
  font-weight: 500;
}

.ourservices_container .list_of_services .services-card img{
  width: 80px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}


/* Recommended Products Section */

.recommended_products_container{
  padding: 10px 0px;
}


.recommended_productslist_list{
  display: flex;
  overflow-x: scroll;
  width: 100vw;
  box-sizing: border-box;
}

.recommended_products_container .recommended_products_header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 90vw;
  margin: auto;
  margin-bottom: 10px;
}

.recommended_products_header p{
  font-size: larger;
  font-weight: 600;
  margin: 0;
  width: 70%;
}

.recommended_products_header button{
  height: 30px;
  border: none;
  width: 30px;
  font-size: larger;
  color: white;
  background-color: rgb(128, 128, 128, 0.5);
  margin: 0px 5px;
}

.recommended_productslist_list .products-card{
  min-width: 25vw;
  height: fit-content;
  padding: 10px;
  box-sizing: border-box;
  transition: transform 0.5s ease-in-out;
  border: 0.5px solid lightgrey;
}

.recommended_productslist_list .products-card.extra{
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  max-width: 200px;
  min-height: 300px;
  border: 0.5px solid lightgrey;
  margin: auto 5px;
  margin-right: 5vw;
}

.recommended_productslist_list .products-card.extra p{
  color: grey;
  text-align: center;
}
.recommended_productslist_list .products-card.extra button{
  background-color: black;
  color: white;
  min-width: fit-content;
}

.recommended_productslist_list .products-card img{
  width: 100%;
  height: 200px;
  /* max-height: 120px; */
}

.recommended_productslist_list .productInfo p{
  font-size: small;
}

.recommended_productslist_list .products-card button{
  font-size: small;
  width: fit-content;
  padding: 5px;
  background-color: white;
  border: 1px solid lightgrey;
  color: black;
}

.recommended_productslist_list .products-card button:hover{
  background-color: #00B900;
  color: white;
  border: transparent;
}

 /* Categories Menu */
 .categoriesMenu_container{
  visibility: hidden;
  display: none;
}

/* Extra */
.shopSticker_container{
  display: none;
}

.homePageFront_container .poster_card{
  display: none;
}

/* Ocean Design Section */

.oceanDesign_container{
  position: relative;
  background: #03151f;
  width: 100vw;
  height: 1100px;
  margin: 10px auto 0px auto;
}

#ocean_card {
  width: 100%;
  height: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: linear-gradient(to bottom, #0a192f, #031428);
  overflow: hidden;
}

#ocean_card h2{
  color: white;
  text-align: center;
}

.wave-01 {
  position: absolute;
  width: 150vw;
  height: 70%;
  left: -10%;
  top: 70%;
  background-color: rgba(0,190,255,0.4);
  border-radius: 45%;
  animation: rotateclock 12s linear infinite;
}


.wave-02 {
  position: absolute;
  width: 150vw;
  height: 70%;
  left: -10%;
  top: 77%;
  background-color: rgba(0,70,110,0.4);
  border-radius: 43%;
  animation: rotateanticlock 8s linear infinite;
}

.wave-03 {
  position: absolute;
  width: 150vw;
  height: 70%;
  left: -10%;
  top: 80%;
  background-color: rgba(0,90,110,0.4);
  border-radius: 40%;
  animation: rotateanticlock 4s linear infinite;
}

/* SkinCare Section */

.resuableComponent_container{
  padding: 30px 10px;
  box-sizing: border-box;
}

.container{
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.container .imageCard img{
  width: 300px;
  height: 300px;
  border-radius: 5px;
}

.resuableComponent_container .card{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resuableComponent_container .card button{
  background-color: black;
  border: transparent;
  padding: 20px;
  color: white;
  border-radius: 20px;
  transition:  all 0.5s ease-in-out;
}

.resuableComponent_container .card .textContent_card h2{
  font-size: xx-large;
}

.resuableComponent_container .card button:hover{
  background-color: white;
  color: black;
}

.posterdiv_container .container{
  background-color: rgb(218, 165, 32, 0.6);
  padding: 20px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.plaindiv_container .container{
  border: 0.9px solid lightgrey;
  padding: 10px;
  border-radius: 10px;
}

.introDiv_component .container{
  display: flex;
  flex-direction: column-reverse;
  background-color: goldenrod;
  padding: 30px;
  border-radius: 10px;
}

/*Testominal Section */

.testominalComponent_container{
  width: 90vw;
  margin: auto;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  padding: 20px 10px;
}

.testominalComponent_container .testominal_cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  transition: transform 1s ease-in-out;
  border-radius: 10px;
}


.testominalComponent_container .testominal_cards .single_card{
  width: 100vw;
  animation: slideshow 25s infinite;
  position: relative;
  
}

.testominalComponent_container .testominal_cards .single_card p{
  position: absolute;
  top: 56%;
  width: 80vw;
  margin: 5vw;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
  animation: display 15s infinite;
  font-size: small;
}

.testominalComponent_container .testominal_cards .single_card p span{
  position: absolute;
  top: 52%;
  right: 10px;
  width: fit-content;
  margin-top: 40px;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: white;
  z-index: 1;
  animation: display 15s infinite;
}

.testominalComponent_container .testominal_cards .single_card img{
  width: 90%;
  border-radius: 10px;
}

/* Skin Care component NavBar */

.skinCareComponentNavBar_container{
  height: 15vh;
  border: 1px solid transparent;
  background-color: lightgrey;
  width: 90vw;
  margin: 10px auto;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow:hidden;
}

.skinCareComponentNavBar_container img{
  height: 80%;
  border-radius: 30%;
  margin: 10px;
}

.skinCareComponentNavBar_container .hamburgerIcon{
  width: 40px;
  height: 40px;
}

.skincare_extra div{
  position: relative;
}

.skincare_extra h3{
  position: absolute;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  top: -10px;
  right: 0;
}

.waves{
  width: 90vw;
  height: 90vh;
  overflow: hidden;
  position: absolute;
  top: 10vh;
}

.wave_card{
  position: absolute;
  width: 100vw;
  height: 70%;
  left: -10%;
  top: 20%;
  background-color: rgb(255, 215, 0, 0.4);
  border-radius: 43%;
  animation: rotateclock 30s linear infinite;
}

.wave2_card{
  position: absolute;
  width: 100vw;
  height: 70%;
  right: -10%;
  top: 20%;
  background-color: rgb(255, 215, 0, 0.2);
  border-radius: 53%;
  animation: rotateanticlock 30s linear infinite;
}

/* SkinCare Drawer Menu  */

.SkinCareDrawerMenu-container{
  background-color: white;
  width: 100vw;
  height: 90vh;
  border-radius: 0px 10px;
  border: 1px solid rgb(128, 128, 128, 0.4);
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  overflow-y: scroll;
  padding-bottom: 10vh;
  box-sizing: border-box;
}

.skinCareFront_card{
  height: 90vh;
  overflow: hidden;
}


.SkinCareDrawerMenu-container.open{
  transform: translateX(0%);
}

.SkinCareDrawerMenu-container .drawer_heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 5px auto;
  background-color: lightgrey;
  border-radius: 5px;
}

.SkinCareDrawerMenu-container.open p{
  background-color: lightgrey;
  padding: 8px 10px;
  box-sizing: border-box;
  width: 90%;
  margin-left: 20px;
  font-weight: 600;
  font-size: large;
}

.SkinCareDrawerMenu-container .drawer_heading p{
  margin-left: 20px;
  font-weight: 600;
  font-size: large;
  color: black;
  background-color: transparent;
}

.close{
  background-color: none;
  color: black;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 5px 10px;
  font-weight: 500;
  width: 40px;
  height: 35px;
  font-size: large;
  margin-right: 10px;
}

.close:hover{
  background-color: red;
  color: white;
  border: none;
}

.SkinCareDrawerMenu-container .productSearch-card{
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SkinCareDrawerMenu-container .productSearch-card input{
  width: 80%;
  height: 5vh;
  border-radius: 10px;
  border: 1px solid rgb(255, 165, 0, 0.6);
  padding: 5px 10px;   
}

.SkinCareDrawerMenu-container .productSearch-card img{
  height: 40px;
  margin-left: 10px;
}

.SkinCareDrawerMenu-container ul{
  list-style: none;
  padding-left: 30px;
}

.SkinCareDrawerMenu-container.open ul p{
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(139, 69, 19,0.3);
}

.SkinCareDrawerMenu-container .shops_card li {
  margin: 10px;
}

.horizontalMenu_card{
  display: none;
}


/* DashBoardVNav Section */

.mainpage_container {
  display: flex;
}

.dashboardVNav_container{
  width: 15vw;
  height: 100vh;
  position: fixed;
  border-right: 1px solid rgb(211, 211, 211, 0.3);
}

.dashboard_container{
  display: flex;
  flex-direction: column;
}

.dashboardHNav_container{
  height: 10vh;
  width: 85vw;
  border-bottom: 0.5px solid rgb(211, 211, 211, 0.3);
  display: flex;
  justify-content: space-between;
}

.dashboardBody_container{
  width: 85vw;
  height: 90vh;
  background-color: rgb(211, 211, 211, 0.2);
}

.dashboardVNav_container .brandName_card{
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.dashboardVNav_container .brandName_card h2{
  font-size: 1.3rem;
  margin: 2px;
}

.brandName_card img{
  width: 50px;
  border-radius: 10px;
  border: 1px solid rgb(211, 211, 211, 0.3);
  margin-bottom: 10px;
}

.brandName_card p{
  font-size: small;
  text-transform: capitalize;
  color: royalblue;
  margin: 0;
}

.dashboardVNav_container .vNav_menu{
  margin-top: 30px;
}

.dashboardVNav_container .vNav_menu ul{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
  /* margin-left: 20%; */
  height: 35vh;
}

.dashboardVNav_container .vNav_menu ul li{
  /* text-align: center; */
  margin-left: 10%;
  height: 15%;
  color: grey;
  font-weight: 600;
  padding: 10px 0px;
}

.dashboardVNav_container .vNav_menu ul .selected{
  background-color: rgb(128, 128, 128, 0.2);
  border-radius: 4px;
  width: 88%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: all 0.3s ease-in-out;
}

/* DashBoardVNav Section Ends */

/* PortalHome Section */
.homeComponent_container {
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 15%;
}
.homeComponent_container .summary_info__container{
  width: 70%;
  height: 90vh;
  margin-top: 80px;
}

.summary_info__container h2{
  font-size: 1.2rem;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.summary_info__container h2 span{
  font-size: 0.7rem;
  border-left: 1px solid orange;
  padding: 5px;
  color: royalblue;
}

.summary_info__container .customer_list_container{
  background-color: white;
  width: 95%;
  margin: auto;
  border-radius: 5px;
  border: 1px solid rgb(211, 211, 211, 0.8);
  overflow-y: scroll;
}

.customer_list_container table{
  font-size: small;
  width: 100%;
  border-collapse: collapse;
  color: black;
  user-select: none;
}

.customer_list_container table img{
  height: 80px;
}

.customer_list_container table th, td {
  border: 0.5px solid rgb(211, 211, 211, 0.3);
  padding: 5px;
  text-align: center;
  font-size: large;
}
.customer_list_container table th {
  background-color: #f2f2f2;
}
tr:nth-child(even) {
  background-color: #f1eded;
}

/* PortalHome Section Ends */

/* PortalProductInputComponent Starts */
.portalProductInputComponenet_container h2{
  margin-top: 40%;
}
.portalProductInputComponenet_container form{
  position: absolute;
  top: 20%;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px;
  width: 20%;
}

.portalProductInputComponenet_container form .back-btn{
  font-size: large;
  width: 60px;
  background-color: transparent;
  height: fit-content;
  color: grey;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 0;
}

.portalProductInputComponenet_container form .back-btn:hover{
  background-color: lightgrey;
}

.portalProductInputComponenet_container form .input_card{
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.portalProductInputComponenet_container form .input_card .btns{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.portalProductInputComponenet_container form .input_card .btns button{
  width: '50%';
  height: 50px;
}

.portalProductInputComponenet_container form .input_card input,
.portalProductInputComponenet_container form .input_card textarea,
.portalProductInputComponenet_container form .input_card select{
  width: 90%;
  border-radius: 10px;
  padding: 10px;
  border: 0.5px solid grey;
  margin-top: 10px;
}

.portalProductInputComponenet_container form button{
  width: 100%;
  background-color: #00B900;
  border: none;
  color: white;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
}

/* PortalProductInputComponent Ends */

/* PortalCatalogue Component Starts */
.PortalCatalogue_container{
  margin-left: 20%;
  margin-top: 10%;
  border: 0.5px solid grey;
  padding: 10px;
  border-radius: 10px;
}

.PortalCatalogue_container span{
  background-color: rgb(0, 185, 0, 0.4);
  padding: 5px;
  margin-left: 10px;
  border-radius: 5px;
}

.PortalCatalogue_container .disable{
  background-color: rgb(255, 0, 0, 0.3);
}

.PortalCatalogue_container .input_card{
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.PortalCatalogue_container .input_card input{
  width: 50vw;
  height: 40px;
  border-radius: 10px;
  border: 0.5px solid grey;
  padding: 0px 10px;
  margin-top: 5px;
}

.PortalCatalogue_container button{
  margin: 15px;
  background-color: #00B900;
  color: white;
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 10px;
  font-size: large;
}

.dropbox-button{
  background-color: lightgrey;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 10px;
  width: fit-content;
}

.dropboxWithFile{
  background-color: rgb(0, 185, 0, 0.5);
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 10px;
  width: fit-content;
  color: white;
}

.Shipping{
  width: 40vw;
}

/*Shipping Details Section Starts*/
.shippingDetails_container{
  width: 95vw;
  height: 100vh;
  margin: auto;
}

.shippingDetails_container img{
  width: 100%;
  height: 80%;
  margin: auto;
}

/*Shipping Details Section Starts*/

/*Login Section Starts */

.login_container{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: beige;
}
.login_container h2{
  text-align: center;
}
.login_container form {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.login_container form .input_card{
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.login_container form .input_card input{
  height: 30px;
  border-radius: 10px;
  border: 0.5px solid grey;
  padding: 5px 10px;
  margin-top: 5px;
}

.login_container form button{
  margin: auto;
  background-color: #00B900;
  color: white;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 10px;
  margin-top: 25px;
}
/*Login Section Ends */

.categoriesMenu_card .categories {
  overflow-y: scroll;
  height: 362px;
}

@keyframes slideshow {
  0% { transform: translateX(0%);}
  30% { transform: translateX(-100%);}
  60% { transform: translateX(-200%);}
  100% { transform: translateX(-300%);}
}

@keyframes display {
  0% { display: none;}
  50% { display: block; }
  100% { display: none;}
}


@keyframes rotateclock {
  100% { transform: rotate(0deg) translateX(0);}
  50% { transform: rotate(360deg) translateX(40vw);}
  100% { transform: rotate(0deg) translateX(0);}
}

@keyframes rotateanticlock {
  0% { transform: rotate(0deg) translateX(0);}
  50% { transform: rotate(-360deg) translateX(40vw);}
  100% { transform: rotate(0deg) translateX(0);}
}


@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}


/* Media query for larger screens, e.g., tablets and desktops */
@media screen and (min-width: 750px) {


  /* Our Service Section */
  .list_of_services .services-card{
    margin: 20px;
    padding: 20px;
  }
  
  .services-card small{
    font-size: x-large;
  }
  
  .ourservices_container .list_of_services .services-card img{
    width: 150px;
    margin-bottom: 15px;
  }
  /* Product On Sale Section */
  .displayboard-container{
    width: 90vw;
    margin: auto;
  }

  .alterdisplayboard-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 245, 220, 0.8);
    padding: 20px;
    margin: auto;
  }
  
  .alterdisplayboard-container img{
    width: 300px;
    margin: auto;
  }
  .displayboard small{
    width: 150px;
    font-size: x-large;
  }
  
  .displayboard-container .displayboardImage{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .displayboard-container .displayboardImage img{
    width: 150px;
    margin: 10px 40px;
  }

  .displayboard-container .displayboard p{
    font-size: xx-large;
    width: 80%;
    margin: 0px auto 20px auto;
    text-align: center;
  }
  
  .displayboard .price{
    width: 200px;
  }
  
  .displayboard h3, 
  .displayboard h4{
    font-size: x-large;
  }
  .displayboard button{
    width: 150px;
    height: 60px;
    font-size: x-large;
  }

  /* WhatsApp chatbot Section */
  .whatsappChatInterFace-container .messageInput_container button {
    width: 100px;
    height: 50px;
    font-size: larger;
    margin-right: 30px;
  }
  /*Footer Section */
  .footer-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .footer-container .footer_details{
    display: flex;
    justify-content: space-between;
    width: 90vw;
    margin: auto;
  }
  .footer-container p{
    text-align: center;
  }
  .footer_details .contactInfo ul,
  .footer-brandServices ul{
    padding: 0;
    margin: 0;
  }
  .link{
    width: 90vw;
    margin: auto;
    font-weight: 600;
  }

  /* NavBar Search Section */
  .navbar-hamburgericon-card .search_input_container{
    height: 50px;
    width: 85%;
  }
  .NavBarMenu-container .mobile-navbar-container .navbar-hamburgericon-card img{
    width: 40px;
    height: 40px;
  }
  
  .navbar-hamburgericon-card .search_input_container .searchInput{
    height: 30px;
    width: 85%;
    font-size: larger;
  }

  .search_input_container .searchInput::placeholder {
    padding: 0px 20px;
  }

  .other_details .catalogues{
    width: 200px;
    margin-right: 20px;
  }
  .navbarbrandnameheader-container .brand-logo-card .brand-logo{
    width: 100px;
    height: 100px;
  }

  .navbarbrandnameheader-container h4{
    font-size: x-large;
    margin-left: 10px;
  }

  /* Single Product Section */
  
  
  .singleproductpage-container .product-card img{
    height: 400px;
    padding: 5px;
  }
  
  .singleproductpage-container .product-details{
    width: 90vw;
    padding: 10px;
    border-bottom: 1px solid grey;
  }
  
  .singleproductpage-container .product-details h3{
    margin: 0;
    font-size: x-large;
    font-weight: 300;
  }
  .cart-buttons .counter button{
    font-size: xx-large;
    background-color: rgb(128, 128, 128, 0.1);
    border: transparent;
    padding: 5px 15px;
  }
  
  .cart-buttons .counter span{
    font-size: xx-large;
  }
  
  .productdescription-container small {
    padding: 0px 30px;
    font-size: x-large;
  }
  
  .singleproductpage-container .productdescription-container p{
    font-size: larger;
    margin: 20px;
  }
  .more-btn{
    background-color: #00B900;
    width: 200px;
    height: 60px;
    font-size: x-large;
    color: white;
    border: transparent;
    border-radius: 5px;
    font-weight: 500;
  }
  /* Recommended Products Section */

.recommended_products_container{
  padding: 10px 0px;
}


.recommended_productslist_list{
  display: flex;
  overflow-x: scroll;
  width: 100vw;
  box-sizing: border-box;
}

.recommended_products_container .recommended_products_header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 90vw;
  margin: auto;
  margin-bottom: 10px;
}

.recommended_products_header p{
  font-size: x-large;
  font-weight: 600;
  margin: 0;
}

.recommended_products_header button{
  height: 50px;
  border: none;
  width: 40px;
  font-size: xx-large;
  color: white;
  background-color: rgb(128, 128, 128, 0.5);
  margin: 0px 5px;
}


.recommended_productslist_list .products-card img{
  width: 150px;
}

.recommended_productslist_list .productInfo p{
  font-size: medium;
}

.recommended_productslist_list .products-card button{
  padding: 10px;
  font-size: larger;
}

/* Shopping cart Section */
.cart_header p{
  font-size: xx-large;
  margin-bottom: 0px;
}
.cart_header button{
  height: 60px;
  border-radius: 50% ;
  font-size: xx-large;
  width: 60px;
}

.shoppingcart-container .cart-msg{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  margin: 10px;
  padding: 20px;
}

.shoppingcart-container.open .cart{
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 80%;
}

.shoppingcart-container .cart-msg img{
  height: 40px;
  margin-right: 30px;
}
.shoppingcart-container .cart-msg p{
  font-size: larger;
}

.products .counter{
  display: flex;
  margin: 0px 15px;
}

.products .counter span{
  width: 40px;
  font-size: x-large;
}

.products .counter .add{
  font-size: x-large;
  width: 30px;
}
.products .counter .subtract{
  font-size: x-large;
  width: 30px;
}

.shoppingcart-container .products img{
  width: 80px;
}

.products .product-image{
  margin-right: 30px;
}

.products .product-info{
  width: fit-content;
  margin: 0;
}

.shoppingcart-container .products p,
.shoppingcart-container .products h3{
  width: 95%;
  margin: 0px;
  font-size: x-large;
}

.shoppingcart-container .products h3{
  font-weight: 400;
  font-size: large;
}


/* WhatsApp ChatInterface */

.whatsappChatInterFace-container .messageInput_container input{
  width: 90%;  
  margin-right: 10px;
}

/* Ocean Design*/
.oceanDesign_container{
  height: 850px;
}

.wave-01 {
  width: 110vw;
  height: 110%;
}


.wave-02 {
  width: 150vw;
  height: 130%;
}

.wave-03 {
  width: 150vw;
  height: 150%;
}

/* Skin Care Section */

.skinCareComponentNavBar_container .hamburgerIcon{
  display: none;
}
.horizontalMenu_card{
  display: block;
  width: 55%;
}

.skincare_extra div{
  display: none;
}

.horizontalMenu_card ul{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  list-style: none;
}

.horizontalMenu_card ul li{
  font-weight: 600;
  font-size: x-large;
  transition: transform 0.5s ease-in-out;
}

.horizontalMenu_card ul li:hover{
  transform: scale(1.2);
  color: royalblue;
}

.horizontalMenu_card ul li div{
  position: relative;
}

.horizontalMenu_card ul li h3{
  position: absolute;
  top: -10px;
  right: 0;
  background-color: white;
  padding: 2px 3px;
  border-radius: 10px;
  font-size: medium;
}

.horizontalMenu_card ul li img{
  width: 30px;
  border: 1px solid black;
  padding: 5px;
}

.skincare_extra{
  display: none;
}
}


/* Media query for larger screens, e.g., laptops and desktops */
@media screen and (min-width: 1024px) {
  body {
    font-size: 18px;
    /* Add other styles for even larger screens here */
  }

  .NavBarMenu-container .mobile-navbar-container .navbar-hamburgericon-card {
    display: flex;
    justify-content: center;
  }

  .hamburgerIcon{
    display: none;
  }

  .hamburgerIcon_desktop_alt{
    display: block;
  }
  .NavBarMenu-container .mobile-navbar-container .navbar-hamburgericon-card .hamburgerIcon{
    display: none;
  }
  /* Product Listing Section */

  .navbarbrandnameheader-container .brand-logo-card .brand-logo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .productslist{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .productlisting-container .products-card{
    width: 25%;
    border-radius: 0px;
    padding: 10px 5px ;
    text-align: center;
    box-sizing: border-box;
  }
  
  .productlisting-container .products-card img{
    width: 70%;
  }

  .productInfo{
    margin-bottom: 5px;
  }
  
  .productInfo p{
    margin: 3px;
    text-align: left;
    font-size: medium;
    width: 70%;
  }
  
  .productlisting-container .products-card .productInfo p span{
    font-weight: 500;
    font-size: large;
    border-radius: 10px;
    color: black;
  }

  .productlisting-container .products-card .productInfo .price{
    font-size: small;
    color: orange;
  }
  
  .products-card button,
  .productlisting-container button{
    width: 60%;
    padding: 10px 5px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid lightgrey;
    color: black;
    font-weight: 500;
  }

  .productlisting-container button:hover{
    background-color: #00B900;
    border: 1px solid transparent;
    color: white;
  }


  .products-card button:hover{
    background-color: #00B900;
    border: 1px solid transparent;
    color: white;
  }
  /* SlideShow Container Section */

  .homePageFront_container{
    display: flex;
    width: 90vw;
    margin: 22vh auto 30px auto;
  }

  .homePageFront_container .poster_card{
    width: 300px;
    height: 280px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .homePageFront_container .poster_card img{
    width: 250px;
    height: 280px;
    margin: auto;
    border-radius: 10px;
  }

  .homePageFront_container .poster_card p{
    position: absolute;
    top: 10px;
    left: 75px;
    right: 75px;
    width: 150px;
    background-color: white;
    color: black;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: small;
    animation: blink 1s infinite;
    transition: all 0.5s ease-in-out;
  }

  .homePageFront_container .poster_card p:hover{
    background-color: black;
    color: white;
  }

  .homePageFront_container .upperDisplay_card{
    display: flex;
  }

  .shopSticker_container{
    visibility: visible;
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    height: 320px;
    padding: 10px 0px ;
    margin: auto;
  }

  .shopSticker_container img{
    max-width: 150px;
    min-width: 100px;
    height: 150px;
    margin: 0px 3px;
    border: 0.5px solid lightgrey;
    border-radius: 10px;
  }

  .slideshow-container {
    width: 45vw;
    height: 45vh;
    border-radius: 10px;
    position: relative;
    margin: 10px 10px 40px 10px;

  }

  /* ProductOnSale section starts */

  .displayboard-container{
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    padding: 20px 10px;
    margin: 10px;
  }

  .displayboard-container .displayboard p{
    color: grey;
  }

  .displayboard h1{
    text-align: center;
  }


  /* Categories Menu */
  .categoriesMenu_container{
    visibility: visible;
    width: 30vw;
    /* border-right: 0.5px solid lightgrey; */
    display: flex;
    max-width: 300px;
    min-width: 210px;
  }

  .categoriesMenu_card{
    margin: 10px auto;
    width: 90%;
    height: fit-content;
    padding: 10px ;
    border: 0.5px solid lightgrey;
  }

  .categoriesMenu_card .drawer_heading,
  .categoriesMenu_card p{
    width: 100%;
    padding: 8px 5px;
    background-color: rgb(0, 0, 0);
    text-align: center;
    font-weight: 500;
    box-sizing: border-box;
    border-radius: 5px;
    color: white;
  }

  .categoriesMenu_card ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }


  .categoriesMenu_card ul li{
    margin: 25px 5px ;
    font-weight: 500;
    color: grey;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid lightgrey;
  }

  .categoriesMenu_card ul li span{
    font-weight: 700;
    color: lightgrey;
  }

  /*Recommended Products */

  .recommended_productslist_list{
    display: flex;
    overflow-x: scroll;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 5vw;
  }

  /* ProductOnSale section starts */

.displayboard-container{
  width: 65%;
}

.displayboard-container .displayboard p{
  font-size: large;
}
.alterdisplayboard-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 220, 0.8);
  padding: 20px;
  margin: auto;
}

.alterdisplayboard-container img{
  width: 300px;
  margin: auto;
}

.displayboard h1{
  font-size: x-large;
}


.displayboard button{
  width: 150px;
  height: 40px;
  font-size: medium;
  margin: auto;
}


.displayboard small{
  width: 150px;
}

.catalogue_container{
  width: 250px;
  margin: auto; 
  position: relative; 
  display: block;
}

 .catalogue_container img{
  width: 100%;
}

 .catalogue_container p{
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  background-color: black;
  border-radius: 10px;
  padding: 10px;
  color: white;
  width: 180px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  animation: blink 1s infinite;
  transition: all 0.5s ease-in-out;
  text-transform: uppercase;
}


 .catalogue_container p:hover{
  background-color: white;
  color: black;
}

/* Shopping cart Section */

.shoppingcart-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: auto;

}

.shoppingcart-container.open {
  transform: translateY(0%);
  height: 110vh;
  width: 105vw;
  overflow-y: scroll;
}

.shoppingcart-container .back_button{
  width: 100px;
  margin-right: 10px;
  display: block;
}

.shoppingcart-container.open .cancel {
  width: 100px;
  max-height: 80px;
  background-color: transparent;
  border: 1px solid lightgrey;
  color: grey;
  font-size: x-large;
  font-weight: 600;
  margin-top: 3vw;
  padding: 10px;
  border-radius: 5px;
}

.shoppingcart-container.open .cartDetails_card{
  width: 50%;
  height: 90%;
  overflow-x: hidden;
  overflow-y:scroll;
  border: 0.5px solid lightgrey;
  padding: 10px;
}

.shoppingcart-container .carttotal-container{
  position: relative;
  border: 0.5px solid lightgrey;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 30%;
}

.shoppingcart-container .carttotal-container button{
  padding: 10px;
  margin-top: 10px;
}

.cartTotal_extra_card{
  display: block;
}

.cartTotal_extra_card textarea{
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* WhatsApp ChatInterface */

.whatsappChatInterFace-container .messageInput_container input{
  width: 90%;  
  margin-right: 10px;
}
/* Ocean Design*/
.oceanDesign_container{
  height: 120vh;
}

.wave-01 {
  width: 100vw;
  height: 150%;
}


.wave-02 {
  width: 120vw;
  height: 180%;
}

.wave-03 {
  width: 140vw;
  height: 200%;
}

.wave_card{
  width: 130vw;
  height: 130%;
}
.wave2_card{
  width: 130vw;
  height: 130%;
}

/* Skin Care Section */

.skincare_extra{
  display: none;
}
.horizontalMenu_card{
  display: block;
  width: 40%;
}

.horizontalMenu_card ul{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  list-style: none;
}

.horizontalMenu_card ul li{
  font-weight: 600;
  font-size: x-large;
  transition: transform 0.5s ease-in-out;
}

.horizontalMenu_card ul li img{
  width: 30px;
  border: 1px solid black;
  padding: 5px;
}

.horizontalMenu_card ul li:hover{
  transform: scale(1.2);
  color: royalblue;
}

/*Testominal Section */

.testominalComponent_container{
  width: 60vw;
  padding: 20px;
}

.testominalComponent_container .testominal_cards .single_card{
  width: 60vw;
}

.testominalComponent_container .testominal_cards .single_card p{
  width: 45vw;
}

/* Reusable Component Section */

.resuableComponent_container{
  padding: 30px 10px;
  box-sizing: border-box;
}

.container{
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.container .imageCard img{
  width: 300px;
  height: 300px;
  border-radius: 10px;
}

.resuableComponent_container .card .textContent_card{
  font-size: x-large;
  width: 60%;
}

.posterdiv_container .container{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 90%;
}

.plaindiv_container .container{
  width: 80%;
  text-align: center;
}

}